@font-face {
    font-family: 'Nyata FTR Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Nyata FTR Regular'), url('NyataFTR-Regular.woff') format('woff'), url('NyataFTR-Regular.otf') format('otf');
    }

.button{
    color: White;
    text-decoration: none;
    text-align: center;

    font-family: 'Nyata FTR Regular';
    font-weight: normal;

    width: fit-content;
    height: fit-content;

    border-radius: 5px;   
}
    .button.large{
        background-color: #22562F;

        font-size: 24px;
    
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
        .button.large:hover{
            background-color: #004B07;
        }
    .button.small{
        background-color: #1D743B;
        font-size: 24px;
    
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
        .button.small:hover{
            background-color: #004B07;
        }
    .button.empty{
        cursor: pointer;
        background-color: white;
        color : #1D743B;
        font-size: 24px;
    
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
        .button.empty:hover{
            color: #004B07;
            text-decoration: underline;
        }

.pullquote{
    color: #22562F;
    font-size: 24px;
    border-left: 5px solid #1D743B;
    margin: 10px;
}



.header{
    display: flex;
    height: 110px;
    width: auto;
    
    padding-left: 20%;
    padding-right: 20%;
    border-bottom: 5px solid #22562F;
}
    .header.nav{
        width: 100%;

        padding: 0;
        justify-content: flex-end;
        align-items: center;
    }
    .header.image{
        background-color: white;
        width: 337px;
        height: 100px;
        z-index: 10;

        padding: 15px;
        margin: 0;
        margin-top: 45px;
        margin-left: -190px;

        border: 5px solid #22562F;
        border-radius: 10px;
    }

.mainVideo{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
    .mainVideo.image{
        width: 100%;
        position: absolute;
        z-index: 2;

    }
        .mainVideo img{
            width: 100%;
            height: 50%;
        }
    .mainVideo.video{
        z-index: 1;
        padding-bottom: 5px;
    }
        .mainVideo video{
            width: 100%;
            height: auto;
        }
.mainImage{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 5px 5px -5px #717171;
}
    .mainImage.image{
        position: absolute;
        z-index: 2;
        box-shadow: none;
    }
        .mainImage img{
            width: 100%;
            height: 100%;
        }
    .mainImage.video{
        z-index: 1;
    }
    .mainImage.video img{
        height: auto;
    }
.shp0 { fill: #22562f } 
.shp1 { fill: #ffffff } 
        
.title{
    color: #1D743B;

    font-size: 50px;
    font-family: 'Nyata FTR Regular';
    font-weight: normal;
    text-transform: uppercase;
    
    width: fit-content;

    border-bottom: 5px solid #22562f;

    margin-bottom: 75px;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
}
    .titleWrapper{
        width: 100%;
    }
.title.history{
    color: #fafafa;
    
    font-size: 40px;
    font-family: 'Nyata FTR Regular';
    font-weight: normal;
    text-transform: uppercase;
        
    width: fit-content;
    
    border-bottom: 5px solid #22562f;
    
    margin-bottom: 75px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    }
    .titleWrapper.history{
        width: 100%;
    }

.portfolioWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: stretch;

    padding-right: 20%;
    padding-left: 20%;
    padding-bottom: 20px;
    margin-bottom: 50px;
}
.folioCard{
    display: flex;
    flex-direction: column;


    background-color: #fafafa;
    box-shadow: 2.5px 2.5px 5px #717171;
    flex-grow: 1;

    margin: 5px;
    padding-bottom: 5px;

    border-radius: 5px;
    text-align: center;
}
    .folioCard.logo{
        flex-grow: 0;
        box-shadow: none;
        padding: 15px;
        margin: 0;
        width: auto;
    }
    .folioCard.logo img{
        width: 100%;
    }
    .folioCard.content{
        flex-grow: 1;

        box-shadow: none;
        margin: 0;
        margin-bottom: 10px;
        height: fit-content;

        font-family: 'Nyata FTR Regular';
        font-weight: normal;

        padding: 10px;
    }
    .folioCard.button{
        flex-grow: 0;
        box-shadow: none;
        width: auto;
        
    }
    .folioButton{
        align-items: flex-end;
        text-decoration: none;
        color: #1D743B;

        font-size: 24px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;  
    }
        .folioButton:hover{
            color: #004B07;
            text-decoration: underline;
        }

.historyContent{
    font-size: 24px;
    color: white;
    font-family: 'Nyata FTR Regular';
    font-weight: 100;
    text-align: justify;
    text-justify: inter-word;
    margin-left: 15%;
    margin-right: 15%;
}
    .historyContent.pullquote{
        color: white;
        font-size: 40px;
        border-left: 10px solid #22562F;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
        width: 45%;
        float: left;
    }

.pageContent{
    font-size: 30px;
    font-family: 'Nyata FTR Regular';
    font-weight: normal;
    
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 200px;
}
    .pageContent.pullquote{
        color: #1D743B;
        font-size: 40px;
        border-left: 10px solid #22562F;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 20px;
        width: 45%;
        float: left;
    }
    .pageContent form{
        align-content: center;
        margin-right: 20%;
        margin-left: 20%;
    }   
        .pageContent form label{
            float: left;
        }
        .pageContent form input{
            text-align: left;
            width: 100%;
            padding: 7.5px;
            margin: 5px;

            box-sizing: border-box;

            font-size: 20px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;

            border: 1px solid black;
            border-radius: 5px;
        }
        .pageContent form select{
            width: 100%;
            padding: 7.5px;
            margin: 5px;

            box-sizing: border-box;

            font-size: 20px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;

            border: 1px solid black;
            border-radius: 5px;
        }
        .pageContent form textarea{
            width: 100%;
            padding: 7.5px;
            margin: 5px;

            box-sizing: border-box;

            font-size: 20px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;

            border: 1px solid black;
            border-radius: 5px;
        }
        .pageContent form .submit{
            width: auto;
            padding: 10px;

            background-color: #22562F;
            color: white;
        }
            .pageContent form .submit:hover{
                background-color: #004B07;
            }
    .pageContent .captcha{
        display: flex;
       align-items: center;
       justify-content: center;

    }

.jobs.wrapper{
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
    margin-bottom: 100px;

    display: flex;
    flex-direction: row;
    text-align: center;
    }
    .jobs.opportunity{
        width: 66.66%;
    }
        .jobs.opportunity.card{
            background-color: #fafafa;
            margin-top: 25px;
            margin-bottom: 25px;
            margin-right: 25px;
            text-align: center;
            box-shadow: 2.5px 2.5px 5px #717171;
            width: auto;
            border-radius: 2.5px;
            padding: 20px;

            font-family: 'Nyata FTR Regular';
            font-weight: normal;
        }
            .jobs.opportunity.card h1{
                color: #1D743B;
                font-size: 30px;
                font-family: 'Nyata FTR Regular';
                font-weight: normal;
                margin: 0;
            }
            .jobs.opportunity.card p{
                font-family: 'Nyata FTR Regular';
                font-weight: normal;
                font-size: 20px;
            }
            .jobs.opportunity.card ul{
                text-align: left;
                padding-left: 10%;
                font-size: 25px;
                color: #1D743B
            }
            .jobs.opportunity.card.button{
                background-color: #22562F;

                font-size: 24px;
            
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 7px;
                padding-bottom: 7px;
                
                box-shadow: none;
            }
            .jobs.jobs.opportunity.card.button:hover{
                background-color: #004B07;
            }
        .jobs.opportunity.title{
            color: #1D743B;

            font-size: 40px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;
            text-transform: uppercase;
            
            width: fit-content;

            border-bottom: 5px solid #22562f;

            margin: 0;
            padding: 0;
            margin-bottom: 50px;
            margin-top: 20px;
            margin-right: auto;
            margin-left: auto;
        }
    .jobs.twitter{
        width: 33.33%;
        margin-left: 25px;
        margin-right: 0px;
        margin-top: 117px;
        box-shadow: 2.5px 2.5px 5px #717171;
        border-radius: 2.5px;

        
    }


.footer{
    background-color: #717171;

    display: flex;
    flex-direction: row;
    justify-content: center;

    font-family: 'Nyata FTR Regular';
    font-weight: normal;
    text-align: center;

    padding-left: 20%;
    padding-right: 20%;
    padding-bottom: 50px;
}
    .footer div{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    .footer h1{
        color: white;

        font-size: 24px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;
    }
    .footer a{
        text-decoration: none;
        color: white;

        font-size: 20px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;
    }
    .footer a:hover{
        text-decoration: underline;
    }
    .footer h2{
        margin: 10px;

        text-decoration: none;
        color: white;

        font-size: 20px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;
    }
    .footer span{
        font-size: 25px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;
    }
    .footer form{
        align-content: center;
        padding-right: 5%;
        padding-left: 5%;
    }
        .footer form input{
            width: 100%;
            padding: 7.5px;
            margin-top: 5px;
            margin-bottom: 5px;

            font-size: 20px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;

            border: 0px;
            border-radius: 5px;
        }
        .footer form textarea{
            width: 100%;
            padding: 7.5px;
            margin-top: 5px;
            margin-bottom: 5px;

            font-size: 20px;
            font-family: 'Nyata FTR Regular';
            font-weight: normal;

            border: 0px;
            border-radius: 5px;
        }
        .footer form .submit{
            width: auto;
            padding: 10px;
            cursor: pointer;
            background-color: #22562F;
            color: white;
        }
            .footer form .submit:hover{
                background-color: #004B07;
            }
    .footer .captcha{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .headerMobile{
        display: none;
    }
    .mobileDropdown{
        display: none;
    }
@media only screen and (max-width: 1500px){
    .mainImage.video img{
        height: 1300px;
    }
    .footer{
        flex-wrap: wrap;
        padding: 10px;
    }
    .footer div{
        width: 100%;
    }
    .footer form{
        padding-right: 20%;
        padding-left: 20%;
    }
}
@media only screen and (max-width: 1050px){
    .mainImage.video img{
        height: 1400px;
    }
}
@media only screen and (max-width: 1000px){
    .header{
        display: none;
    }
    .headerMobile{
        display: flex;
        justify-content: flex-start;
        height: 100px;
        padding: 10px;
        border-bottom: 5px solid #22562F;
    }
    .mainLogo{
        height: 100%;
        width: auto;
    }
    .hamburgerWrapper{
        display:flex;
        width: 10%;
        position: absolute;
        top: 35px;
        right: 30px;
    }
    .hamburger{
        display: block;
        text-decoration: none;
        border: 0px;
        background-color: transparent;
        z-index: 3;
    }
    .hamburger.element{
        background-color: #6b6b6b;
        width: 50px;
        height: 7.5px;
        margin: 5px;
        border-radius: 5px;
    }
    .mobileDropdown.show{
        display: block;
        position: absolute;

        width: 100%;
        height: 200px;

        z-index: 2;
        color: white;
        text-align: center;

        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
    .mobileDropdown.hidden{
        display: none;
    }
    .mobileDropdown.show.text{
        position: static;
        width: 100%;
        height: 10px;

        padding-top: 5px;

        text-decoration: none;
        text-align: center;

        font-size: 30px;
        font-family: 'Nyata FTR Regular';
        font-weight: normal;

        z-index: 3;
    }
    .mobileDropdown.text:hover{
        color: #ededed;
    }
    .jobs.wrapper{
        margin-left: 20%;
        margin-right: 20%;
        flex-wrap: wrap;
    }
    .jobs.opportunity{
        width: 100%;
    }
    .jobs.opportunity.card{
        margin: 0;
        margin-top: 20px;
    }
    .jobs.twitter{
        width: 100%;
        margin: 0;
        margin-top: 20px;
    }
    .pageContent{
        margin: 10px;
        font-size: 20px;
    }
    .pageContent.pullquote{
        font-size: 30px;
    }
    .title{
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 950px){
    .mainImage.video img{
        height: 1600px;
    }
}
@media only screen and (max-width: 850px){
    .mainImage.video img{
        height: 1200px;
    }
    .historyContent{
        font-size: 20px;
        font-weight: 100;
        margin: 20px;
    }
    .historyContent.pullquote{
        font-size: 30px;
    }
     
    .title{
        text-align: center;
        width: 100%;
    }

    .portfolioWrapper{
        flex-wrap: wrap;
        padding-left: 10px;
        padding-right: 10px;
    }

    .folioCard.logo{
        height: auto;
        width: 90%;
    }
    
    .folioCard{
        margin-left: 30%;
        margin-right: 30%;
    }
    
    .jobs.wrapper{
        margin: 20px;
    }
    .mainLogo{
        height: 90%; 
        width: auto;
    }
}
@media only screen and (max-width: 700px){
    .folioCard{
        margin-right: 20%;
        margin-left: 20%;
    }
}
@media only screen and (max-width: 500px){
    .mainImage.video img{
        height: 1600px;
    }
    .pageContent form{
        margin: 10px;
    }
    .folioCard{
        margin-left: 20px;
        margin-right: 20px;
    }
}
@media only screen and (max-width: 400px){
    .mainImage.video img{
        height: 1700px;
    }
    .mainLogo{
        height: 90%; 
        width: auto;
    }
}
@media only screen and (max-width: 330px){
    .mainImage.video img{
        height: 1800px;
    }
    .mainLogo{
        height: 80%;
        width: auto;
    }
}